
import React from "react"

import ResponsiveImage from "components/responsiveImage"
import styleNameResolver from "helper/styleNameResolver"
import RichText from "components/richText"

import styles from "./styles.module.scss"

export default class Quote extends React.Component {

	render() {

		const {
			primary: {
				type_size,
				speaker_name,
				quotation_marks,
				quote,
				image
			}

		} = this.props;

		return (

			<div className={`${styles.root} ${styles[styleNameResolver(quotation_marks)]} ${styles[type_size.toLowerCase()]}${!image ? ` ${styles.noImage}` : ""}`}>

				{
					image && <ResponsiveImage src={image.url} />
				}

				<div className={styles.quote}>

					{
						
						quote && <RichText className={styles.text} content={quote} />

					}

					{
						
						speaker_name && <RichText className={styles.speaker} content={speaker_name} />

					}

				</div>

			</div>

		);

	}

}