
import React from "react"

export default class Arrow extends React.Component {

	render() {

		return (
			
			<svg {...this.props} viewBox="0 0 20.12 37.41">

				<polygon points="18.71 37.41 0 18.71 18.71 0 20.12 1.41 2.83 18.71 20.12 36 18.71 37.41"/>

			</svg>

		);

	}

}

