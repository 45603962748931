
import React from "react"

import Image from "components/image"

import styles from "./styles.module.scss";

export default class FullscreenImage extends React.Component {

	render() {

		const {
			primary
		
		} = this.props;

		if(!primary.image)

			return null;

		return (

			<div className={styles.root}>

				<Image {...primary} url={primary.image.url} />

			</div>

		);

	}

}