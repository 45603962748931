
import React from "react"

import styleNameResolver from "helper/styleNameResolver"
import RichText from "components/richText"

import styles from "./styles.module.scss"

export default class Text extends React.Component {

	render() {

		const {
			primary: {
				text,
				text_size
			}
		
		} = this.props;

		return (

			<RichText className={`${styles.root} ${styles[styleNameResolver(text_size)]}`} content={text} />

		);

	}

}