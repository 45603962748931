
import React from "react"

import RichText from "components/richText"

import styles from "./styles.module.scss"

export default class ImageCaption extends React.Component {

	render() {

		const {
			className,
			image_credit,
			image_caption
		
		} = this.props;

		if(!image_caption && !image_credit)

			return null;

		return (

			<div className={`${styles.root}${className ? ` ${className}` : ""}`}>

				{ Boolean(image_credit) && <RichText content={image_credit} /> }

				{ Boolean(image_caption) && <RichText content={image_caption} /> }

			</div>

		);

	}

}
