
import React from "react"
import PrismicDOM from "prismic-dom"

import RichText from "components/richText"
import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class Wrapper extends React.Component {

	render() {

		const {

			primary,
			secondary_credit,
			date_published,
			type,

			className,
			children
		
		} = this.props;

		const dateFormatter = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric"});

		const {
			background_color,
			text_color
		
		} = primary || {};

		return (

			<div className={`${styles.root} ${colorStyles[styleNameResolver(background_color, "background")]} ${colorStyles[styleNameResolver(text_color, "text")]}${styles[type] ? ` ${styles[type]}` : ""}${className ? ` ${className}` : ""}`}>

				{
					(Boolean(date_published) || Boolean(secondary_credit)) && 

						<div className={styles.credit}>

							{
								Boolean(date_published) &&

									<React.Fragment>

										<p>
											Date Posted

											<br />

											{dateFormatter.format(PrismicDOM.Date(date_published))}

										</p>

										<br />

									</React.Fragment>

							}

							{ Boolean(secondary_credit) && <RichText content={secondary_credit} /> }

						</div>

				}

				{children}

			</div>

		);

	}

}