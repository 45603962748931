
import React from "react"

import PostFeed from "components/postFeed"
import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class RelatedPosts extends React.Component {

	render() {

		const {
			related_articles,
			body = []
		
		} = this.props;

		if(!(related_articles || []).length)

			return null;

		const { 
			primary: {
				background_color,
				text_color

			}

		} = body[body.length - 1] || { primary: {...this.props}};


		return (

			<div className={`${colorStyles[styleNameResolver(background_color, "background")]} ${colorStyles[styleNameResolver(text_color, "text")]}`}>

				<h3 className={styles.title}>Related</h3>

				<PostFeed
					feed={related_articles.map(({ primary: { article } }) => ({ node: article }))}
					hasMore={false}
				/>

			</div>

		);

	}

}