
import React from "react"

import RichText from "components/richText"
import styleNameResolver from "helper/styleNameResolver"
import parseHtml from "helper/parseHtml"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class Listicle extends React.Component {

	render() {

		const {
			primary: {
				start_index,
				index_color
			},

			fields = []

		} = this.props;

		return (

			<ol className={`${styles.root}`}>
			
				{
					fields.map(({

						item_title,
						item_content

					}, index) => (

						<ol key={index} className={styles.item}>

							<div className={styles.title}>

								<span className={`${styles.index} ${colorStyles[styleNameResolver(index_color, "text")]}`}>

									{`${index + start_index < 10 ? "0" : ""}${index + start_index}`}

								</span>
							
								{parseHtml(item_title)}

							</div>

							{
						
								item_content && <RichText className={styles.content} content={item_content} />

							}

						</ol>

					))
				}
			
			</ol>

		);

	}

}