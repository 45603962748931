
import React from "react"
import { Swiper } from "swiper/dist/js/swiper.esm.js"

import parseHtml from "helper/parseHtml"
import RichText from "components/richText"
import Image from "components/image"
import ImageCaption from "components/imageCaption"
import ArrowIcon from "components/svg/arrow"

import styles from "./styles.module.scss"

import "swiper/dist/css/swiper.css"

export default class MediaSlideshow extends React.Component {

	constructor() {

		super();

		this.state = {

			selected: 0

		};

		this.slideshowRef = React.createRef();


	}

	componentDidMount() {

		this.slideshow = 

			new Swiper(

				this.slideshowRef.current,

				{
					autoHeight: true,

					on: {
						slideNextTransitionStart: () => this.setState({ selected: this.state.selected + 1 }),
						slidePrevTransitionStart: () => this.setState({ selected: this.state.selected - 1 })
					}

				}

			);

	}

	render() {

		const {
			primary: {
				title,
				text
			},

			fields = []
		
		} = this.props;

		const {
			selected

		} = this.state;

		return (

			<div className={`${styles.root}`}>

				{Boolean((title || [{ text: "" }])[0].text.length) && <div className={styles.title}>{parseHtml(title)}</div>}

				<div className={styles.slideshow}>

					<div className="swiper" ref={this.slideshowRef}>

						<div className="swiper-wrapper">

							{
								fields.map(({ image, image_caption }, index) => image ? (

									<div className="swiper-slide" key={index}>

										<Image url={image.url} />
									
									</div>

								) : null

								).filter(v => v)
							}

						</div>

					</div>

					{	Boolean(selected) && 

							<ArrowIcon className={styles.prevArrow} onClick={() => this.slideshow.slidePrev()} /> 

					}

					{ 
						selected !== fields.length - 1 && 

							<ArrowIcon className={styles.nextArrow} onClick={() => this.slideshow.slideNext()} />
					}

				</div>

				<ImageCaption

					className={styles.caption}

					{
						...(() => {

							const {
								image_caption = [{}]
							
							} = fields[selected];

							return {

								image_caption: [{

									type: "paragraph", 
									text: `${selected + 1}/${fields.length}`,
									spans: []

								}],
								
								image_credit: image_caption 

							};

						})()
					} 
				/>

				{text && <RichText className={styles.description} content={text} /> }

			</div>

		);

	}

}