
import React from "react"

import parseHtml from "helper/parseHtml"

import styles from "./styles.module.scss"

export default class Interview extends React.Component {

	render() {

		const {
			fields
		
		} = this.props;

		return (

			<ol className={`${styles.root}`}>

				{
					fields.map(({ speaker_name, speaker_response }, index) => (

						<li key={index} className={styles.item}>

							<div className={styles.title}>

								{parseHtml(speaker_name)}

							</div>

							<div className={styles.text}>

								{parseHtml(speaker_response)}

							</div>

						</li>

					))
				}

			</ol>

		);

	}

}