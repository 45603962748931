
import React from "react"

import Wrapper from "components/post/content/wrapper"
import Text from "components/post/content/text"
import Quote from "components/post/content/quote"
import MediaGrid from "components/post/content/mediaGrid"
import Listicle from "components/post/content/listicle"
import Interview from "components/post/content/interview"
import FullscreenImage from "components/post/content/fullscreenImage"
import MediaSlideshow from "components/post/content/mediaSlideshow"

import styles from "./styles.module.scss"

export default class Content extends React.Component {

	render() {

		const {
			_meta = {},
			body = [],
			secondary_credit,
			date_published
		
		} = this.props;

		return (body || []).map(

			(data, index) =>

				<Wrapper 
					{...data}
					key={index} 
					className={styles[data.type]}

					secondary_credit={!index ? secondary_credit : undefined}
					tags={index + 1 === body.length ? _meta.tags : undefined}
					date_published={!index ? date_published : undefined}

				>

					{{

						"text": <Text {...data} />,

						"image_gallery": <MediaGrid {...data} />,

						"quote": <Quote {...data} />,

						"listicle": <Listicle {...data} />,

						"interview": <Interview {...data} />,

						"fullscreen_image": <FullscreenImage {...data} />,

						"media_slideshow": <MediaSlideshow {...data} />

					}[data.type]}

				</Wrapper>


		).filter(v => v);

	}

}