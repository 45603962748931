
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { bindActionCreators } from "redux"
import { graphql } from "gatsby"
import scrollIntoView from "scroll-into-view"

import PageMetaData from "components/pageMetaData"
import Header from "components/post/header"
import Content from "components/post/content"
import Wrapper from "components/post/content/wrapper"
import RelatedPosts from "components/post/relatedPosts"
import { post, postHeader } from "graphQLFragments"

import { update as updateApp } from "state/app"
import styles from "./styles.module.scss"

const headerRef = React.createRef();

export const query = graphql`

  query PostBySlug($uid: String!) {
    
    prismic {

	    post_default(uid: $uid lang: "en-us") {
	      
			...Post

	    }

		allSites {

			edges {

				node {

					newsletter_popup

				}

			}

		}

	}

  }

`;

function PostTemplate({ 

	data: { 

		prismic: {

			post_default,

			allSites: { edges: [{ node: {

				newsletter_popup

			} }] }

		} 

	},

	location,

	postsViewed,
	scrollToPostContent,
	updateApp 

}) { 

	if(!post_default)

		return null;

	useEffect(() => {

		if(window.dataLayer)

			(post_default._meta.tags || []).map(

				tag => {

					setTimeout(() => {

							window.dataLayer.push({

								"event": "article-tag-page-view", 
								"title": (post_default.title || [{}])[0].text,
								"tag": tag

							});

						}, 100

					);

				}

			);

		if(headerRef.current && scrollToPostContent) {

			updateApp({ scrollToPostContent: false });

			if("ontouchstart" in document.documentElement)

				return;

			setTimeout(() => {

				scrollIntoView(

					headerRef.current,

					{

						align: {

							topOffset: (window.innerHeight - headerRef.current.clientHeight) + -(window.innerHeight / 5)

						}

					}

				);

			}, 200);

		}

	}, [(post_default._meta || {}).uid]);

	useEffect(() => {

		if(newsletter_popup.toLowerCase() === "disable newsletter popup" || !post_default || localStorage.getItem("showNewsletter"))

			return;

		const viewed = 

			Array.from(

				new Set(

					[post_default._meta.uid, ...postsViewed]

				)

			);

		if(viewed.length === postsViewed.length)

			return;

		updateApp({ postsViewed: viewed });

		if(viewed.length === 2) {

			updateApp({ showNewsletter: true });

			localStorage.setItem("showNewsletter", "true");

		}

	});

	const {
		title,
		subtitle,
		description,
		image,
		_meta = {}
	
	} = post_default;

	return (

		<article className={styles.root}>

			<PageMetaData

				title={`${(title || [{}])[0].text}${subtitle ? ` ${(subtitle || [{}])[0].text}` : ""}`}
				description={(description || [{}])[0].text}
				imageUrl={(image || {}).url}
				url={location.href}

			/>

			<div ref={headerRef}>

				<Header {...post_default} />

			</div>

			<Content {...post_default} />


			{
				_meta.tags && 

					<Wrapper {...(post_default.body || [{}])[(post_default.body || [{}]).length - 1]} className={styles.tags}>

						{

							_meta.tags.map((tag, index) =>

								<Link key={index} to={`/tag/${tag.toLowerCase()}`}>

									{tag}

								</Link>

							)

						}

				</Wrapper>

			}

			<RelatedPosts {...post_default} />

		</article>

	);

};

PostTemplate.fragments = [post, postHeader];

export default connect(

	state => ({

		postsViewed: state.app.postsViewed,
		scrollToPostContent: state.app.scrollToPostContent

	}),

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(PostTemplate);
