
import React from "react"

import ResponsiveImage from "components/responsiveImage"
import ImageCaption from "components/imageCaption"

import styles from "./styles.module.scss"

export default class Image extends React.Component {

	render() {

		const {
			url,
			image_size = "fullscreen"
		
		} = this.props;

		return (

			<div className={`${styles.root} ${styles[image_size.toLowerCase()]}`}>

				<ResponsiveImage src={url} />

				<ImageCaption {...this.props} className={styles.caption} />

			</div>

		);

	}

}