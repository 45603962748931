
import React from "react"

import Image from "components/image"

import styles from "./styles.module.scss"

export default class MediaGrid extends React.Component {

	render() {

		const {
			fields = []
		
		} = this.props;

		return (

			<div className={`${styles.root}`}>

				{
					fields.map(

						(data, index) => {

							data = data || {};

							return (

								<Image 
									key={index} 
									url={(data.grid_image || {}).url} 
									{...data} 
								/>

							);

						}

					)
				}

			</div>

		);

	}

}